<template>
  <div class="page">
    <div class="message">
      <img src="./img/successIcon.png" alt="" />
      <div class="text">发布成功～</div>
    </div>
    <div class="banner">
      <img src="./img/successBg.png" alt="" />
    </div>
    <div class="submitBtn" @click="toPath('addMyVisit')">继续提交</div>
    <div class="cheackBtn" @click="toPath('myVisit')">去查看</div>
  </div>
</template>

<script>
export default {
  name: "addMyVisitSuccess",
  methods: {
    toPath(path) {
      this.$router.replace({
        name: path,
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  .cheackBtn {
    text-align: center;
    line-height: 66px;
    margin: 0 auto;
    margin-top: 30px;
    width: 550px;
    height: 66px;
    border-radius: 10px;
    border: 2px solid #5f5bff;
    font-size: 30px;
    font-weight: 600;
    color: #5e5aff;
  }
  .submitBtn {
    text-align: center;
    line-height: 66px;
    margin: 0 auto;
    margin-top: 182px;
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #9591ff 0%, #5f5bff 100%);
    border-radius: 10px;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
  }
  .banner {
    margin: 0 auto;
    margin-top: 142px;
    width: 568px;
    img {
      width: 100%;
    }
  }
  .message {
    margin-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      font-size: 36px;
      font-weight: 600;
      color: #5e5aff;
    }
    img {
      margin-right: 12px;
      width: 40px;
    }
  }
}
</style>
